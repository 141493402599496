/* purgecss start ignore */

.ReactTable{
  position:relative;
  display:flex;
  flex-direction:column;
  border:1px solid rgba(0,0,0,0.1);
}

.ReactTable *{
  box-sizing:border-box
}

.ReactTable .rt-table{
  flex:auto 1;
  display:flex;
  flex-direction:column;
  align-items:stretch;
  width:100%;
  border-collapse:collapse;
  overflow:auto
}

.ReactTable .rt-thead{
  flex:1 0 auto;
  display:flex;
  flex-direction:column;
  -webkit-user-select:none;
  user-select:none;
}

.ReactTable .rt-thead.-headerGroups{
  background:rgba(0,0,0,0.03);
  border-bottom:1px solid rgba(0,0,0,0.05)
}

.ReactTable .rt-thead.-filters{
  border-bottom:1px solid rgba(0,0,0,0.05);
}

.ReactTable .rt-thead.-filters input,.ReactTable .rt-thead.-filters select{
  border:1px solid rgba(0,0,0,0.1);
  background:#fff;
  padding:5px 7px;
  font-size:inherit;
  border-radius:3px;
  font-weight:normal;
  outline-width:0
}

.ReactTable .rt-thead.-filters .rt-th{
  border-right:1px solid rgba(0,0,0,0.02)
}

.ReactTable .rt-thead.-header{
  box-shadow:0 2px 15px 0 rgba(0,0,0,0.15)
}

.ReactTable .rt-thead .rt-tr{
  text-align:center
}

.ReactTable .rt-thead .rt-th,.ReactTable .rt-thead .rt-td{
  padding:5px 5px;
  line-height:normal;
  position:relative;
  border-right:1px solid rgba(0,0,0,0.05);
  transition:box-shadow .3s cubic-bezier(.175,.885,.32,1.275);
  box-shadow:inset 0 0 0 0 transparent;
}

.ReactTable .rt-thead .rt-th.-sort-asc,.ReactTable .rt-thead .rt-td.-sort-asc{
  box-shadow:inset 0 3px 0 0 rgba(0,0,0,0.6)
}

.ReactTable .rt-thead .rt-th.-sort-desc,.ReactTable .rt-thead .rt-td.-sort-desc{
  box-shadow:inset 0 -3px 0 0 rgba(0,0,0,0.6)
}

.ReactTable .rt-thead .rt-th.-cursor-pointer,.ReactTable .rt-thead .rt-td.-cursor-pointer{
  cursor:pointer
}

.ReactTable .rt-thead .rt-th:last-child,.ReactTable .rt-thead .rt-td:last-child{
  border-right:0
}

.ReactTable .rt-thead .rt-th:focus{
  outline-width:0
}

.ReactTable .rt-thead .rt-resizable-header{
  overflow:visible;
}

.ReactTable .rt-thead .rt-resizable-header:last-child{
  overflow:hidden
}

.ReactTable .rt-thead .rt-resizable-header-content{
  overflow:hidden;
  text-overflow:ellipsis
}

.ReactTable .rt-thead .rt-header-pivot{
  border-right-color:#f7f7f7
}

.ReactTable .rt-thead .rt-header-pivot:after,.ReactTable .rt-thead .rt-header-pivot:before{
  left:100%;
  top:50%;
  border:solid transparent;
  content:" ";
  height:0;
  width:0;
  position:absolute;
  pointer-events:none
}

.ReactTable .rt-thead .rt-header-pivot:after{
  border-color:rgba(255,255,255,0);
  border-left-color:#fff;
  border-width:8px;
  margin-top:-8px
}

.ReactTable .rt-thead .rt-header-pivot:before{
  border-color:rgba(102,102,102,0);
  border-left-color:#f7f7f7;
  border-width:10px;
  margin-top:-10px
}

.ReactTable .rt-tbody{
  flex:99999 1 auto;
  display:flex;
  flex-direction:column;
  overflow:auto;
}

.ReactTable .rt-tbody .rt-tr-group{
  border-bottom:solid 1px rgba(0,0,0,0.05);
}

.ReactTable .rt-tbody .rt-tr-group:last-child{
  border-bottom:0
}

.ReactTable .rt-tbody .rt-td{
  border-right:1px solid rgba(0,0,0,0.02);
}

.ReactTable .rt-tbody .rt-td:last-child{
  border-right:0
}

.ReactTable .rt-tbody .rt-expandable{
  cursor:pointer;
  text-overflow:clip
}

.ReactTable .rt-tr-group{
  flex:1 0 auto;
  display:flex;
  flex-direction:column;
  align-items:stretch
}

.ReactTable .rt-tr{
  flex:1 0 auto;
  display:inline-flex
}

.ReactTable .rt-th,.ReactTable .rt-td{
  flex:1 0;
  white-space:nowrap;
  text-overflow:ellipsis;
  padding:7px 5px;
  overflow:hidden;
  transition:.3s ease;
  transition-property:width,min-width,padding,opacity;
}

.ReactTable .rt-th.-hidden,.ReactTable .rt-td.-hidden{
  width:0 !important;
  min-width:0 !important;
  padding:0 !important;
  border:0 !important;
  opacity:0 !important
}

.ReactTable .rt-expander{
  display:inline-block;
  position:relative;
  margin:0;
  color:transparent;
  margin:0 10px;
}

.ReactTable .rt-expander:after{
  content:'';
  position:absolute;
  width:0;
  height:0;
  top:50%;
  left:50%;
  -webkit-transform:translate(-50%,-50%) rotate(-90deg);
  transform:translate(-50%,-50%) rotate(-90deg);
  border-left:5.04px solid transparent;
  border-right:5.04px solid transparent;
  border-top:7px solid rgba(0,0,0,0.8);
  transition:all .3s cubic-bezier(.175,.885,.32,1.275);
  cursor:pointer
}

.ReactTable .rt-expander.-open:after{
  -webkit-transform:translate(-50%,-50%) rotate(0);
  transform:translate(-50%,-50%) rotate(0)
}

.ReactTable .rt-resizer{
  display:inline-block;
  position:absolute;
  width:36px;
  top:0;
  bottom:0;
  right:-18px;
  cursor:col-resize;
  z-index:10
}

.ReactTable .rt-tfoot{
  flex:1 0 auto;
  display:flex;
  flex-direction:column;
  box-shadow:0 0 15px 0 rgba(0,0,0,0.15);
}

.ReactTable .rt-tfoot .rt-td{
  border-right:1px solid rgba(0,0,0,0.05);
}

.ReactTable .rt-tfoot .rt-td:last-child{
  border-right:0
}

.ReactTable.-striped .rt-tr.-odd{
  background:rgba(0,0,0,0.03)
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover{
  background:rgba(0,0,0,0.05)
}

.ReactTable .-pagination{
  z-index:1;
  display:flex;
  justify-content:space-between;
  align-items:stretch;
  flex-wrap:wrap;
  padding:3px;
  box-shadow:0 0 15px 0 rgba(0,0,0,0.1);
  border-top:2px solid rgba(0,0,0,0.1);
}

.ReactTable .-pagination input,.ReactTable .-pagination select{
  border:1px solid rgba(0,0,0,0.1);
  background:#fff;
  padding:5px 7px;
  font-size:inherit;
  border-radius:3px;
  font-weight:normal;
  outline-width:0
}

.ReactTable .-pagination .-btn{
  -webkit-appearance:none;
  appearance:none;
  display:block;
  width:100%;
  height:100%;
  border:0;
  border-radius:3px;
  padding:6px;
  font-size:1em;
  color:rgba(0,0,0,0.6);
  background:rgba(0,0,0,0.1);
  transition:all .1s ease;
  cursor:pointer;
  outline-width:0;
}

.ReactTable .-pagination .-btn[disabled]{
  opacity:.5;
  cursor:default
}

.ReactTable .-pagination .-btn:not([disabled]):hover{
  background:rgba(0,0,0,0.3);
  color:#fff
}

.ReactTable .-pagination .-previous,.ReactTable .-pagination .-next{
  flex:1 1;
  text-align:center
}

.ReactTable .-pagination .-center{
  flex:1.5 1;
  text-align:center;
  margin-bottom:0;
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  align-items:center;
  justify-content:space-around
}

.ReactTable .-pagination .-pageInfo{
  display:inline-block;
  margin:3px 10px;
  white-space:nowrap
}

.ReactTable .-pagination .-pageJump{
  display:inline-block;
}

.ReactTable .-pagination .-pageJump input{
  width:70px;
  text-align:center
}

.ReactTable .-pagination .-pageSizeOptions{
  margin:3px 10px
}

.ReactTable .rt-noData{
  display:block;
  position:absolute;
  left:50%;
  top:50%;
  -webkit-transform:translate(-50%,-50%);
  transform:translate(-50%,-50%);
  background:rgba(255,255,255,0.8);
  transition:all .3s ease;
  z-index:1;
  pointer-events:none;
  padding:20px;
  color:rgba(0,0,0,0.5)
}

.ReactTable .-loading{
  display:block;
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  background:rgba(255,255,255,0.8);
  transition:all .3s ease;
  z-index:-1;
  opacity:0;
  pointer-events:none;
}

.ReactTable .-loading > div{
  position:absolute;
  display:block;
  text-align:center;
  width:100%;
  top:50%;
  left:0;
  font-size:15px;
  color:rgba(0,0,0,0.6);
  -webkit-transform:translateY(-52%);
  transform:translateY(-52%);
  transition:all .3s cubic-bezier(.25,.46,.45,.94)
}

.ReactTable .-loading.-active{
  opacity:1;
  z-index:2;
  pointer-events:all;
}

.ReactTable .-loading.-active > div{
  -webkit-transform:translateY(50%);
  transform:translateY(50%)
}

.ReactTable .rt-resizing .rt-th,.ReactTable .rt-resizing .rt-td{
  transition:none !important;
  cursor:col-resize;
  -webkit-user-select:none;
  user-select:none
}

/* purgecss end ignore */
.container{
  width:100%
}

@media (min-width: 475px){
  .container{
    max-width:475px
  }
}

@media (min-width: 640px){
  .container{
    max-width:640px
  }
}

@media (min-width: 768px){
  .container{
    max-width:768px
  }
}

@media (min-width: 1024px){
  .container{
    max-width:1024px
  }
}

@media (min-width: 1280px){
  .container{
    max-width:1280px
  }
}

@media (min-width: 1536px){
  .container{
    max-width:1536px
  }
}

.u-focus:focus{
  outline:2px solid transparent;
  outline-offset:2px;
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(26, 188, 156, var(--tw-ring-opacity))
}

.u-button{
  display:inline-flex;
  align-items:center;
  justify-content:center;
  border-radius:9999px;
  border-width:1px;
  padding-left:1.25rem;
  padding-right:1.25rem;
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  font-weight:500;
  line-height:1rem;
  --tw-shadow:0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.u-button:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

.u-panel{
  z-index:30;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:space-between
}

@media (min-width: 768px){
  .u-panel{
    flex-direction:row;
    align-items:center
  }
}

.u-horizontal-center{
  display:flex;
  justify-content:center
}

.u-vertical-center{
  display:flex;
  align-items:center
}

.u-center{
  display:flex;
  align-items:center;
  justify-content:center
}

.u-black-ring{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(0, 0, 0, var(--tw-ring-opacity));
  --tw-ring-opacity:0.05
}

.u-reverse-flex-col-to-row{
  display:flex;
  flex-direction:column-reverse
}

.u-reverse-flex-col-to-row > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(0px * var(--tw-space-x-reverse));
  margin-left:calc(0px * calc(1 - var(--tw-space-x-reverse)));
  --tw-space-y-reverse:0;
  margin-top:calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(1rem * var(--tw-space-y-reverse));
  --tw-space-y-reverse:1
}

@media (min-width: 640px){
  .u-reverse-flex-col-to-row{
    flex-direction:row
  }

  .u-reverse-flex-col-to-row > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(1rem * var(--tw-space-x-reverse));
    margin-left:calc(1rem * calc(1 - var(--tw-space-x-reverse)));
    --tw-space-y-reverse:0;
    margin-top:calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(0px * var(--tw-space-y-reverse))
  }
}

.nav-item .nav-item-popover{
  left:42px;
  border-top-left-radius:0 !important;
  border-bottom-left-radius:0 !important;
  transition:max-width 1s;
  max-width:0
}

.nav-item .nav-item-text{
  display:none
}

.nav-item:hover .nav-item-popover{
  transition:max-width 1s;
  max-width:300px
}

.nav-item:hover .nav-item-text{
  white-space:nowrap;
  overflow:hidden;
  display:block
}

.no-arrows input::-webkit-outer-spin-button,.no-arrows input::-webkit-inner-spin-button{
  -webkit-appearance:none;
  margin:0
}

.no-arrows input[type=number]{
  -moz-appearance:textfield
}

.line-clamp-1{
  overflow:hidden;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:1
}

.save-panel{
  width:calc(100% - 15em);
  margin-left:-1rem
}

.uafrica-modal-overlay{
  z-index:11
}

.uafrica-modal{
  padding-top:1rem;
  z-index:11
}

.uafrica-modal .content{
  max-height:calc(100vh - 90px) !important;
  max-width:calc(95vw) !important
}

.uafrica-modal .save-panel{
  width:calc(100% + 3rem);
  margin-left:-1.5rem;
  margin-right:-1.5rem;
  padding-bottom:0 !important;
  bottom:0 !important
}

.uafrica-modal .ua-modal-buttons-panel{
  width:calc(100%);
  margin-left:-1.5rem;
  margin-right:-1.5rem;
  bottom:0 !important
}

.uafrica-modal .ua-modal-actions-panel{
  background-color:#fff;
  position:fixed !important;
  padding-left:1.5rem;
  padding-right:1.5rem;
  width:calc(100%);
  margin-top:1rem;
  margin-left:-1.5rem;
  margin-right:-1.5rem;
  top:0 !important;
  box-shadow:0 1px 2px 0 rgba(0,0,0,.05);
  min-height:40px
}

.large-modal{
  width:calc(95vw);
  max-width:calc(95vw) !important
}

.medium-modal{
  width:850px;
  max-width:calc(95vw) !important
}

.small-modal{
  width:450px;
  max-width:calc(95vw) !important
}

.time-picker input::-webkit-outer-spin-button,.time-picker input::-webkit-inner-spin-button{
  -webkit-appearance:none;
  margin:0
}

.time-picker input[type=number]{
  -moz-appearance:textfield
}

.ua-filters-panel>*{
  margin-right:1rem
}

.ua-filters-panel>*:nth-child(1){
  margin-right:1rem !important
}

@media(max-width: 475px){
  .uafrica-modal .content{
    max-height:calc(100vh - 180px) !important;
    max-width:calc(98vw) !important
  }

  .save-panel{
    left:0px;
    width:100%
  }

  .ua-page-actions-panel-children>*{
    margin-bottom:.5rem;
    margin-top:.5rem
  }

  .ua-filters-panel>*{
    margin-bottom:.5rem;
    margin-top:.5rem;
    margin-left:0
  }
}

@media(max-height: 750px){
  .uafrica-modal .content{
    max-height:calc(100vh - 22vh) !important
  }
}

.input-counter{
  text-align:center !important;
  border-radius:0 !important
}

.input-counter::-webkit-outer-spin-button,.input-counter::-webkit-inner-spin-button{
  -webkit-appearance:none;
  margin:0
}

.input-counter[type=number]{
  -moz-appearance:textfield
}

.loader{
  border-top-color:#3498db !important;
  -webkit-animation:loader-spinner 1.5s linear infinite;
  animation:loader-spinner 1.5s linear infinite
}

@-webkit-keyframes loader-spinner{
  0%{
    -webkit-transform:rotate(0deg)
  }

  100%{
    -webkit-transform:rotate(360deg)
  }
}

@keyframes loader-spinner{
  0%{
    -webkit-transform:rotate(0deg);
    transform:rotate(0deg)
  }

  100%{
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg)
  }
}

.button-loader-spinning{
  -webkit-animation:spinLoader 1s infinite linear;
  animation:spinLoader 1s infinite linear
}

@-webkit-keyframes spinLoader{
  from{
    -webkit-transform:scale(1) rotate(0deg);
    transform:scale(1) rotate(0deg)
  }

  to{
    -webkit-transform:scale(1) rotate(360deg);
    transform:scale(1) rotate(360deg)
  }
}

@keyframes spinLoader{
  from{
    -webkit-transform:scale(1) rotate(0deg);
    transform:scale(1) rotate(0deg)
  }

  to{
    -webkit-transform:scale(1) rotate(360deg);
    transform:scale(1) rotate(360deg)
  }
}

.text-danger{
  color:#dc2626
}

.page-info{
  display:inline-block;
  margin:3px 10px;
  white-space:nowrap
}

.page-jump{
  display:inline-block;
  margin-right:5px
}

.page-jump input{
  width:70px;
  text-align:center;
  border-radius:.2rem;
  border:1px solid rgba(0,0,0,.2)
}

.total-pages{
  box-sizing:border-box;
  margin-left:5px
}

.custom-table-container{
  background-color:#fff;
  box-shadow:4px 1px 12px 2px #ececec
}

.scrollable-table-container{
  width:100%;
  overflow:auto;
  position:relative
}

.custom-table{
  font:13px Montserrat !important;
  width:100%
}

.clone-table{
  font:13px Montserrat !important;
  width:100%;
  border-collapse:collapse
}

.custom-table-tr{
  text-align:left;
  border-bottom:none
}

.whitespace-nowrap{
  white-space:nowrap
}

.custom-table-th{
  font:12px Montserrat !important;
  letter-spacing:.07em;
  padding:0;
  height:39px !important;
  border-bottom:1px solid #eee;
  -webkit-transform:uppercase;
  transform:uppercase;
  overflow:hidden;
  text-overflow:ellipsis;
  color:#111827;
  font-weight:300;
  background-color:#f7f9fa;
  border-left:none !important;
  border-right:none !important
}

.custom-table-td{
  white-space:nowrap
}

.draggable{
  cursor:move;
  -webkit-user-select:none;
  user-select:none
}

.placeholder{
  background-color:#d3d3d3;
  border:1px dashed gray
}

.clone-list{
  display:flex
}

.dragging-column .custom-table-td,.dragging-column .custom-table-th{
  padding-left:10px;
  padding-right:10px
}

.dragging{
  background:#fff;
  border:1px dashed gray;
  z-index:999
}

.resizer{
  height:39px;
  width:5px;
  cursor:col-resize
}

.resizer:hover{
  background-color:#d3d3d3
}

.resizer-placeholder{
  height:39px;
  width:5px;
  cursor:default
}

.resizable{
  background-color:#d3d3d3 !important
}

.no-data{
  position:absolute;
  left:50%;
  margin-top:50px;
  z-index:60
}

@media (min-width: 475px){
}

@media (min-width: 640px){
}

@media (min-width: 768px){
}

@media (min-width: 1024px){
}

@media (min-width: 1280px){
}

@media (min-width: 1536px){
}
